// extracted by mini-css-extract-plugin
export var alignDiscLeft = "n_qb g_gj g_cG g_fv";
export var alignLeft = "n_qc g_gj g_cG g_fv";
export var alignDiscCenter = "n_qd g_gk g_cD g_fw";
export var alignCenter = "n_cP g_gk g_cD g_fw";
export var alignDiscRight = "n_qf g_gl g_cH g_fx";
export var alignRight = "n_qg g_gl g_cH g_fx";
export var footerContainer = "n_qh g_fS g_cW";
export var footerContainerFull = "n_qj g_fQ g_cW";
export var footerHeader = "n_kY g_kY";
export var footerTextWrapper = "n_qk g_bz";
export var footerDisclaimerWrapper = "n_k3 g_k3 g_dk";
export var badgeWrapper = "n_ql g_bz g_cz g_cP g_cF g_cJ";
export var footerDisclaimerRight = "n_k4 g_k4 g_cz";
export var footerDisclaimerLeft = "n_k5 g_k5 g_cz";
export var verticalTop = "n_qm g_cz g_cJ g_cN g_cL";
export var firstWide = "n_qn";
export var disclaimer = "n_qp";
export var socialDisclaimer = "n_qq";
export var left = "n_qr";
export var wide = "n_qs g_dy";
export var right = "n_qt g_cK";
export var line = "n_gd g_gf g_dv";
export var badgeDisclaimer = "n_qv g_cC g_cP g_cJ";
export var badgeContainer = "n_qw g_cz g_cH g_cP";
export var badge = "n_qx";
export var padding = "n_qy g_d7";
export var end = "n_qz g_cH";
export var linkWrapper = "n_qB g_fB";
export var link = "n_s g_fB";
export var colWrapper = "n_qC g_dx";
export var media = "n_qD g_cx g_fy";
export var itemRight = "n_qF";
export var itemLeft = "n_qG";
export var itemCenter = "n_qH";
export var exceptionWeight = "n_qJ r_rv";