// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "q_qV g_cD";
export var storyRowWrapper = "q_jm g_jm g_cK";
export var storyLeftWrapper = "q_qW g_cz g_cP";
export var storyWrapperFull = "q_qX g_dD";
export var storyWrapperFullLeft = "q_nP g_dD g_cz g_cP g_cD";
export var contentWrapper = "q_q g_jn";
export var storyLeftWrapperCenter = "q_qY g_jp g_cz g_cD g_cP g_cJ";
export var storyRightWrapperCenter = "q_qZ g_jt";
export var storyHeader = "q_q0 g_js g_bz g_ds";
export var storyHeaderCenter = "q_jr g_jr g_bz g_ds g_fw g_cy";
export var storyParagraphCenter = "q_jq g_jq g_cy g_fw";
export var storyBtnWrapper = "q_q1 g_m g_fX g_bz g_cz g_cG";
export var storyBtnWrapperCenter = "q_jw g_jw g_fX g_bz g_cz g_cD";
export var imageWrapper = "q_qQ g_f9 g_b2";
export var imageWrapperFull = "q_q2 g_bz g_bL g_z g_b2";